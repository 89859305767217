<template>
  <div class="">
    <div class="wrapper" v-if="terminals && stores">
      <trac-back-button>Hardware</trac-back-button>
      <h2 class="mt-4">Register a new terminal</h2>
      <div
        class="h-128 w-full flex justify-center items-center"
        v-if="terminals.length === 0"
      >
        <div
          class="flex flex-col justify-center items-center max-w-sm text-center"
        >
          <img src="./../../assets/svg/terminal.svg" alt="" />
          <p class="font-semibold text-xl mt-6 mb-2 text-gray-500">
            Register a device
          </p>
          <span class="mb-6 text-gray-500"
            >Tap on the blue button below to register your first device</span
          >
          <trac-button @button-clicked="gotoCreateTerminal"
            >New Device</trac-button
          >
        </div>
      </div>
      <div class="" v-else>
        <div class="items-center flex flex-row justify-between mt-8 mb-8">
          <div class="">
            <h3>Total: {{ terminals.length }} terminals</h3>
          </div>
          <div class="">
            <trac-button @button-clicked="gotoCreateTerminal"
              >Add New</trac-button
            >
          </div>
        </div>
        <div class="grid grid-cols-3 gap-20">
          <div class="">
            <div class="flex items-center border rounded pl-2">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.1891 9.25757L13.5108 12.7123C13.7918 13.0046 13.7825 13.4706 13.4904 13.7516C13.353 13.8839 13.1719 13.9567 12.9811 13.9567C12.7795 13.9567 12.5913 13.8766 12.4514 13.7313L9.10445 10.2503C8.1541 10.9182 7.04253 11.27 5.87609 11.27C2.769 11.27 0.241089 8.74209 0.241089 5.635C0.241089 2.52791 2.769 0 5.87609 0C8.98318 0 11.5111 2.52791 11.5111 5.635C11.5111 6.96486 11.0431 8.24229 10.1891 9.25757ZM10.0411 5.635C10.0411 3.33837 8.17272 1.47 5.87609 1.47C3.57946 1.47 1.71109 3.33837 1.71109 5.635C1.71109 7.93163 3.57946 9.8 5.87609 9.8C8.17272 9.8 10.0411 7.93163 10.0411 5.635Z"
                  fill="#808080"
                />
              </svg>
              <input
                class="p-2 w-full focus:outline-none text-sm"
                type="text"
                placeholder="Search"
                @input="filterBySearch"
                v-model="search"
              />
            </div>
          </div>
          <div class="flex items-center">
            <!-- <input type="date" /> -->
            <trac-date-picker
              v-model="date"
              @input="filterByDate"
              placeholder="DD-MM-YYYY"
              class="text-sm w-full"
            ></trac-date-picker>
          </div>
          <div class="-mt-6">
            <trac-dropdown-exteneded
              :neededProperty="'name'"
              @optionSelected="selectOption($event)"
              placement="left"
              :options="stores"
              class="mt-6"
            >
            </trac-dropdown-exteneded>
          </div>
        </div>
        <table class="table-auto w-full mt-10">
          <thead>
            <tr class="text-left">
              <th class="p-2 text-sm">TERMINAL NAME</th>
              <th class="p-2 text-sm">SERIAL NUMBER</th>
              <th class="p-2 text-sm">STORE NAME</th>
              <th class="p-2 text-sm">DATE ADDED</th>
              <!-- <th class="p-2 text-sm"></th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(terminal, i) in filteredTerminals"
              :key="i"
              @click="gotoEditTerminal(terminal)"
              :class="i % 2 === 0 ? '' : 'bg-gray-100'"
              class="cursor-pointer hover:bg-blue-100"
            >
              <td class="p-2 pt-4 text-xs uppercase">
                {{ terminal.assignment.terminal_name }}
              </td>
              <td class="p-2 pt-4 text-xs">
                {{ terminal.assignment.serial_no }}
              </td>
              <td class="p-2 pt-4 text-xs capitalize">
                {{ terminal.assignment.store_name }}
              </td>
              <td class="p-2 pt-4 text-xs">
                {{
                  moment(terminal.assignment.date_assigned).format("DD/MM/YYYY")
                }}
              </td>
              <td class="p-2 pt-4 text-xs">
                <!-- <trac-button disabled variant="small">Change</trac-button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="w-full h-screen" v-else>
      <trac-loading />
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      moment,
      terminals: null,
      filteredTerminals: null,
      stores: null,
      selectedStore: null,
      date: "",
      search: "",
    };
  },
  async created() {
    await this.fetchAllTerminals();
    await this.fetchAllStores();
  },
  computed: {
    // filteredTerminals() {
    //   return this.terminals.filter(
    //     (terminal) =>
    //       (terminal.assignment.terminal_name
    //         .toLowerCase()
    //         .includes(this.search) &&
    //         (this.moment(terminal.assignment.date_assigned).format(
    //           "DD/MM/YYYY"
    //         ) === this.moment(this.date).format("DD/MM/YYYY")) ||
    //         this.date === "") &&
    //       (terminal.assignment.store_name.toLowerCase() ===
    //         this.selectedStore.name.toLowerCase() || this.selectedStore.name === '')
    //   );
    // },
  },
  methods: {
    async fetchAllTerminals() {
      await this.$store.dispatch("FETCH_TERMINAL_BY_BUSINESS");
      const res = this.$store.getters["GET_ALL_TERMINALS"];

      this.terminals = res.data ? res.data.items || [] : [];

      this.filteredTerminals = this.terminals;

      // this.terminals = [
      //   {
      //     _id: "5f4e5e432d8af8174002a251",
      //     created_at: "2020-09-01T15:43:59.295+01:00",
      //     updated_at: "2020-09-02T17:55:31.435+01:00",
      //     serial_no: "TEST_9988766",
      //     terminal_provider_id: "5f4d01d22d8af837086d6879",
      //     terminal_provider_name: "GLOBAL ACCELEREX",
      //     is_assigned: true,
      //     assignment: {
      //       date_assigned: "2020-09-02T17:55:31.435+01:00",
      //       business_id: "5db5d841395aca00046129fa",
      //       store_id: "5e41bc1704da1900048e7726",
      //       store_name: "main",
      //       terminal_name: "THEO TERMINAL2",
      //       terminal_id: "TEST_78666TRU",
      //       serial_no: "TEST_9988766",
      //     },
      //   },
      // ];
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores =[ { name: 'all-stores' }, ...res.data] || [];
      }
    },
    gotoCreateTerminal() {
      this.$router.push({ name: "AddTerminal" });
    },
    selectOption(option) {
      this.selectedStore = option;
      this.filteredTerminals = this.terminals.filter(terminal => terminal.assignment.store_name.toLowerCase() === this.selectedStore.name.toLowerCase() || this.selectedStore.name === 'all-stores');
    },
    filterByDate() {
      this.filteredTerminals = this.terminals.filter(terminal => this.moment(terminal.assignment.date_assigned).format("DD/MM/YYYY") === this.moment(this.date).format("DD/MM/YYYY") || !this.date);
    },
    filterBySearch() {
      this.filteredTerminals = this.terminals.filter(terminal => terminal.assignment.terminal_name.toLowerCase().includes(this.search));
    },
    gotoEditTerminal(terminal) {
      this.$router.push({
        name: "AddTerminal",
        params: {
          terminal: terminal.terminal_provider_id,
          serial: terminal.serial_no,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.xmx-input {
  color: #e2e8f0;
  border: 1px solid #253b95;
}
</style>
